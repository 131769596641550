<template>
  <div>
    <div class="tw-grid tw-gap-x-6 md:tw-grid-cols-2">
      <PanelBox title="Web">
        <div slot="toolbar" class="tw-flex tw-flex-wrap tw-justify-end tw-gap-1">
          <a
            class="btn btn-default btn-xs tw-mr-1"
            target="_blank"
            href="https://docs.google.com/document/d/1P0WciIDGqPqy0wVRbbZrP-cUx76_H2VKevAblfVzQyU/edit#"
          >
            SEO-tips
          </a>
          <template v-if="editWeb">
            <button type="button" class="btn btn-xs btn-primary" @click="saveTexts('web'); editWeb = false">
              <i class="fa fa-save" /> Opslaan
            </button>
            <button type="button" class="btn btn-xs btn-danger" @click="loadEntityDescriptions('web'); editWeb = false">
              <i class="fa fa-times" /> Annuleren
            </button>
          </template>
          <button v-else type="button" class="btn btn-xs btn-default" @click="editWeb = true">
            <i class="fa fa-pencil" /> Bewerken
          </button>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <InlineTranslatable
              v-if="webTabs"
              ref="webTexts"
              :tabs="webTabs"
              :trigger="editWeb"
              @translated="handleTranslate('web')"
              @uncheckPersistToProperties="persistToPropertiesWeb = false"
            >
              <template>
                <div
                  v-for="(value, lang) in getDescriptionSuffix(entityDescriptionsWeb)"
                  :key="lang"
                  :slot="`text-suffix-description_${lang}`"
                  v-html="value"
                >
                </div>
              </template>
            </InlineTranslatable>
          </div>
          <div v-if="editWeb && projectId" class="col-sm-12 tw-mt-2">
            <div class="tw-flex">
              <input type="checkbox" v-model="persistToPropertiesWeb" />
              <label class="tw-ml-2">
                Dezelfde teksten opslaan voor alle gelinkte panden
              </label>
            </div>
            <p class="tw-italic tw-mt-4">
              Opgelet: aan de lange beschrijving worden nog wettelijke vermeldingen toegevoegd voor de portaalwebsites.
              Het aantal beschikbare karakters werd automatisch berekend.
            </p>
            <ul class="tw-italic tw-list-disc tw-ml-4">
              <li v-for="(value, key, index) in projectLegalNotices" :key="index">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </div>

        </div>
      </PanelBox>
      <PanelBox title="Print">
        <div slot="toolbar" class="tw-flex tw-flex-wrap tw-justify-end tw-gap-1">
          <template v-if="editPrint">
            <button type="button" class="btn btn-xs btn-primary" @click="saveTexts('print'); editPrint = false">
              <i class="fa fa-save" /> Opslaan
            </button>
            <button type="button" class="btn btn-xs btn-danger" @click="loadEntityDescriptions('print'); editPrint = false">
              <i class="fa fa-times" /> Annuleren
            </button>
          </template>

          <template v-else>
            <button
              type="button"
              class="btn btn-xs btn-warning tw-mr-1"
              @click="
                // showUI = true, translated = true, copyFromWeb = true
                saveTexts('print', true, true, true)
              "
            >
              Kopiëren van web
            </button>
            <button type="button" class="btn btn-xs btn-default" @click="editPrint = true">
              <i class="fa fa-pencil" /> Bewerken
            </button>
          </template>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <InlineTranslatable
              ref="printTexts"
              v-if="printTabs"
              :tabs="printTabs"
              :trigger="editPrint"
              @translated="handleTranslate('print')"
              @uncheckPersistToProperties="persistToPropertiesPrint = false"
            >
              <template>
                <div
                  v-for="(value, lang) in getDescriptionSuffix(entityDescriptionsPrint)"
                  :key="lang"
                  :slot="`text-suffix-description_${lang}`"
                  v-html="value"
                >
                </div>
              </template>
            </InlineTranslatable>
          </div>
          <div v-if="editPrint && projectId" class="col-sm-12 tw-flex tw-mt-2">
            <input type="checkbox" v-model="persistToPropertiesPrint" />
            <label class="tw-ml-2">
              Dezelfde teksten opslaan voor alle gelinkte panden
            </label>
          </div>
        </div>
      </PanelBox>
      <PanelBox title="Investeringsinformatie">
        <div slot="toolbar" class="tw-flex tw-flex-wrap tw-justify-end tw-gap-1">
          <button
            v-if="showInvestmentTextsCreateButton"
            type="button"
            class="toolbar-button"
            @click="createInvestmentTexts"
          >
            <i class="fa fa-plus" /> Beschrijvingen aanmaken
          </button>

          <template v-else-if="editInvestment">
            <button type="button" class="btn btn-xs btn-primary" @click="saveTexts('investment'); editInvestment = false">
              <i class="fa fa-save" /> Opslaan
            </button>
            <button type="button" class="btn btn-xs btn-danger" @click="loadEntityDescriptions('investment'); editInvestment = false">
              <i class="fa fa-times" /> Annuleren
            </button>
          </template>

          <template v-else>
            <button
              type="button"
              class="btn btn-xs btn-warning tw-mr-1"
              @click="
                // showUI = true, translated = true, copyFromWeb = true
                saveTexts('investment', true, true, true)
              "
            >
              Kopiëren van web
            </button>
            <button type="button" class="btn btn-xs btn-default" @click="editInvestment = true">
              <i class="fa fa-pencil" /> Bewerken
            </button>
          </template>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <InlineTranslatable
              ref="investmentTexts"
              v-if="investmentTabs"
              :tabs="investmentTabs"
              :trigger="editInvestment"
              @translated="handleTranslate('investment')"
            >
              <template>
                <div
                  v-for="(value, lang) in getDescriptionSuffix(entityDescriptionsInvestment)"
                  :key="lang"
                  :slot="`text-suffix-description_${lang}`"
                  v-html="value"
                >
                </div>
              </template>
            </InlineTranslatable>
          </div>
        </div>
      </PanelBox>
    </div>
    <ContentBlocks :property-id="propertyId" :project-id="projectId" />
    <div v-if="propertyId">
      <PanelBox title="Troeven">
        <span slot="toolbar">
          <button
            v-if="showStrengthsCreateButton"
            class='toolbar-button'
            @click="createStrengths"
          >
            <i class="fa fa-plus" /> Troeven aanmaken
          </button>
          <button v-else-if="!showStrengthsForm"
            class="btn btn-xs btn-default"
            @click="toggleStrengthsForm"
          >
            <i class="fa fa-pencil" /> Bewerken
          </button>
        </span>
        <div v-if="!showStrengthsCreateButton ">
          <FormulateForm
            v-if="showStrengthsForm"
            #default="{ isLoading }"
            v-model="strengthsFormValues"
            name="propertyStrengths"
            @submit="submit"
          >
            <div class="tw-grid md:tw-grid-cols-3 tw-gap-x-3">
              <FormulateInput
                type="translatable"
                name="strength_1"
                label="Troef 1"
              />
              <FormulateInput
                type="translatable"
                name="strength_2"
                label="Troef 2"
              />
              <FormulateInput
                type="translatable"
                name="strength_3"
                label="Troef 3"
              />
              <div class="tw-flex tw-flex-row tw-space-x-4">
                <FormulateInput
                  type="submit"
                  :disabled="isLoading"
                  :input-class="['tw-w-full']"
                  :outer-class="['tw-w-full md:tw-w-auto']"
                >
                  <i
                    :class="[
                      'fas tw-mr-1',
                      isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
                    ]"
                  />
                  Opslaan
                </FormulateInput>
                <FormulateInput
                  type="button"
                  :input-class="['tw-bg-error tw-w-full']"
                  :outer-class="['tw-w-full md:tw-w-auto']"
                  @click="cancel"
                >
                  <i class="fa fa-times tw-mr-2"/> Annuleren
                </FormulateInput>
              </div>
            </div>
          </FormulateForm>
          <div v-else class="tw-grid md:tw-grid-cols-3 tw-gap-x-4 tw-gap-y-4">
            <div class="tw-border tw-border-1 tw-p-8 tw-rounded-sm">
              <h3>Troef 1</h3>
              <dl class="tw-grid md:tw-grid-cols-5 tw-mb-2">
                <dt class="tw-col-span-1">Nederlands</dt>
                <dd class="tw-col-span-4">{{ strengths.strength_1.text_nl || "-" }}</dd>
              </dl>
              <dl class="tw-grid md:tw-grid-cols-5 tw-mb-2">
                <dt class="tw-col-span-1">Frans</dt>
                <dd class="tw-col-span-4">{{ strengths.strength_1.text_fr || "-" }}</dd>
              </dl>
              <dl class="tw-grid md:tw-grid-cols-5 tw-mb-2">
                <dt class="tw-col-span-1">Engels</dt>
                <dd class="tw-col-span-4">{{ strengths.strength_1.text_en || "-" }}</dd>
              </dl>
            </div>
            <div class="tw-border tw-border-1 tw-p-8 tw-rounded-sm">
              <h3>Troef 2</h3>
              <dl class="tw-grid md:tw-grid-cols-5 tw-mb-2">
                <dt class="tw-col-span-1">Nederlands</dt>
                <dd class="tw-col-span-4">{{ strengths.strength_2.text_nl || "-" }}</dd>
              </dl>
              <dl class="tw-grid md:tw-grid-cols-5 tw-mb-2">
                <dt class="tw-col-span-1">Frans</dt>
                <dd class="tw-col-span-4">{{ strengths.strength_2.text_fr || "-" }}</dd>
              </dl>
              <dl class="tw-grid md:tw-grid-cols-5 tw-mb-2">
                <dt class="tw-col-span-1">Engels</dt>
                <dd class="tw-col-span-4">{{ strengths.strength_2.text_en || "-" }}</dd>
              </dl>
            </div>
            <div class="tw-border tw-border-1 tw-p-8 tw-rounded-sm">
              <h3>Troef 3</h3>
              <dl class="tw-grid md:tw-grid-cols-5 tw-mb-2">
                <dt class="tw-col-span-1">Nederlands</dt>
                <dd class="tw-col-span-4">{{ strengths.strength_3.text_nl || "-" }}</dd>
              </dl>
              <dl class="tw-grid md:tw-grid-cols-5 tw-mb-2">
                <dt class="tw-col-span-1">Frans</dt>
                <dd class="tw-col-span-4">{{ strengths.strength_3.text_fr || "-" }}</dd>
              </dl>
              <dl class="tw-grid md:tw-grid-cols-5 tw-mb-2">
                <dt class="tw-col-span-1">Engels</dt>
                <dd class="tw-col-span-4">{{ strengths.strength_3.text_en || "-" }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </PanelBox>
    </div>
  </div>
</template>

<script>
import PanelBox from '@/components/iam/PanelBox'
import ContentBlocks from '@/components/properties/ContentBlocks'
import InlineTranslatable from '@/components/iam/inline_components/InlineTranslatable'

import getTranslatableConfig from '@/components/properties/forms/translatableConfig'
import { mapActions, mapGetters } from 'vuex'
import { errorModal, startLoadingModal, successModal, questionModal } from '@/modalMessages'
import { createPropertyDescriptions, createPropertyStrengths, getPropertyDescriptions, getPropertyMarketing, updatePropertyDescriptionsBulk, updatePropertyMarketing } from '@/services/properties'
import { getProjectDescriptions, updateProjectDescriptionsBulk } from '@/services/projects'

export default {
  name: 'EntityTexts',
  components: {
    PanelBox,
    ContentBlocks,
    InlineTranslatable
  },
  data () {
    return {
      editWeb: false,
      editPrint: false,
      editInvestment: false,
      editStrengths: false,
      entityDescriptionsWeb: [],
      entityDescriptionsPrint: [],
      entityDescriptionsInvestment: [],
      persistToPropertiesWeb: false,
      persistToPropertiesPrint: false,
      showStrengthsForm: false,
      strengthsFormValues: {},
      strengths: {}
    }
  },
  computed: {
    ...mapGetters('properties', [
      'getPropertyById',
      'getProjectById'
    ]),
    charLimitConfigForWeb () {
      const baseLongDescriptionLength = 1500
      const reservedTextLengths = {
        nl: this.getReservedTextLength('nl'),
        fr: this.getReservedTextLength('fr'),
        en: this.getReservedTextLength('en')
      }
      const longDescriptions = this.entityDescriptionsWeb.find(({ type }) => type === 2)
      const hasLegalNotices = longDescriptions?.project_legal_notices && !!Object.keys(longDescriptions.project_legal_notices).length
      const projectLegalNoticesTextLengths = {
        nl: this.projectId && hasLegalNotices ? longDescriptions.project_legal_notices.nl.length : 0,
        fr: this.projectId && hasLegalNotices ? longDescriptions.project_legal_notices.fr.length : 0,
        en: this.projectId && hasLegalNotices ? longDescriptions.project_legal_notices.en.length : 0
      }
      return {
        title: 60,
        social: 400,
        shortDescription: 200,
        longDescriptionNl: baseLongDescriptionLength - reservedTextLengths.nl - projectLegalNoticesTextLengths.nl,
        longDescriptionFr: baseLongDescriptionLength - reservedTextLengths.fr - projectLegalNoticesTextLengths.fr,
        longDescriptionEn: baseLongDescriptionLength - reservedTextLengths.en - projectLegalNoticesTextLengths.en
      }
    },
    charLimitConfigDefault () {
      return {
        title: 60,
        shortDescription: 200,
        longDescriptionNl: 1500,
        longDescriptionFr: 1500,
        longDescriptionEn: 1500
      }
    },
    propertyId () {
      return this.$route.meta.entity_type === 'property' ? this.$route.params.id : null
    },
    projectId () {
      return this.$route.meta.entity_type === 'project' ? this.$route.params.id : null
    },
    webTabs () {
      return this.entityDescriptionsWeb.length ? getTranslatableConfig(this.translatableWebConfig, this.charLimitConfigForWeb, 'web') : []
    },
    printTabs () {
      return this.entityDescriptionsPrint.length ? getTranslatableConfig(this.translatablePrintConfig, this.charLimitConfigDefault) : []
    },
    investmentTabs () {
      return this.entityDescriptionsInvestment.length ? getTranslatableConfig(this.translatableInvestmentConfig, this.charLimitConfigDefault) : []
    },
    translatableWebConfig () {
      return this.entityDescriptionsWeb.length ? this.configureTranslatables(this.entityDescriptionsWeb) : []
    },
    translatablePrintConfig () {
      return this.entityDescriptionsPrint.length ? this.configureTranslatables(this.entityDescriptionsPrint) : []
    },
    translatableInvestmentConfig () {
      return this.entityDescriptionsInvestment.length ? this.configureTranslatables(this.entityDescriptionsInvestment) : []
    },
    showStrengthsCreateButton () {
      return !this.strengths?.strength_1 || !this.strengths?.strength_2 || !this.strengths?.strength_3
    },
    showInvestmentTextsCreateButton () {
      return !this.entityDescriptionsInvestment.length && this.propertyId
    },
    projectLegalNotices () {
      if (!this.projectId) return {}
      const notices = this.entityDescriptionsWeb.find(({ type }) => type === 2)?.project_legal_notices
      return {
        Nederlands: notices?.nl,
        Frans: notices?.fr,
        Engels: notices?.en
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('properties', ['loadPropertyContentBlocks', 'loadProjectContentBlocks']),

    async init () {
      try {
        const response = await Promise.all([
          this.loadEntityDescriptions(),
          this.loadPropertyStrengths()
        ])
        return response
      } catch (error) {
        errorModal('Kan gegevens niet laden, probeer het opnieuw.')
      }
    },
    async loadEntityDescriptions (type = null) {
      const response = this.propertyId
        ? await getPropertyDescriptions(this.propertyId)
        : await getProjectDescriptions(this.projectId)
      const results = response.data.results
      if (type === 'web' || type === null) this.entityDescriptionsWeb = results.filter(result => result.medium === 0)
      if (type === 'print' || type === null) this.entityDescriptionsPrint = results.filter(result => result.medium === 1)
      if (type === 'investment' || type === null) this.entityDescriptionsInvestment = results.filter(result => result.medium === 2)
      return response
    },
    async loadPropertyStrengths () {
      try {
        if (!this.propertyId) return
        const response = await getPropertyMarketing(this.propertyId)
        this.strengths = response.data
        return response
      } catch {
        errorModal('Er ging iets mis bij laden van de troeven')
      }
    },
    toggleStrengthsForm () {
      this.editStrengths = !this.editStrengths
      this.showStrengthsForm = !this.showStrengthsForm
      this.strengthsFormValues = this.strengths
    },
    handleTranslate (textType) {
      this.saveTexts(textType, false, true)
    },
    nlValuesValid (nlValues) {
      const notEmpty = value => value.trim()
      return nlValues.some(notEmpty)
    },
    async saveTexts (textType, showUI = true, translated = false, copyFromWeb = false) {
      try {
        if (copyFromWeb) {
          const result = await questionModal('De bestaande tekst wordt overschreven.')
          if (!result.value) return
        }

        if (showUI) startLoadingModal('Bezig met opslaan van teksten...')
        const descriptions = []
        const nlValues = []
        const values = this.$refs[`${textType}Texts`].getValues()
        const translatableConfigMap = {
          web: this.translatableWebConfig,
          print: this.translatablePrintConfig,
          investment: this.translatableInvestmentConfig
        }
        const translatableConfig = translatableConfigMap[textType]

        if (textType !== 'web' && copyFromWeb) {
          const webValues = this.$refs.webTexts.getValues()
          translatableConfig.forEach(config => {
            if (config.field === 'social_title') return
            nlValues.push(values[`${config.field}_nl`])
            descriptions.push({
              id: config.id,
              text_nl: webValues[`${config.field}_nl`],
              text_fr: webValues[`${config.field}_fr`],
              text_en: webValues[`${config.field}_en`]
            })
          })
        } else {
          translatableConfig.forEach(config => {
            if (config.field === 'social_title' && textType !== 'web') return
            nlValues.push(values[`${config.field}_nl`])
            descriptions.push({
              id: config.id,
              text_nl: values[`${config.field}_nl`],
              text_fr: values[`${config.field}_fr`],
              text_en: values[`${config.field}_en`]
            })
          })
        }

        const persist_to_properties = textType === 'web' && this.projectId ? this.persistToPropertiesWeb : this.persistToPropertiesPrint
        this.propertyId
          ? await updatePropertyDescriptionsBulk(this.propertyId, { descriptions })
          : await updateProjectDescriptionsBulk(this.projectId, { descriptions, persist_to_properties })

        await this.loadEntityDescriptions(textType)

        if (showUI) successModal('De teksten zijn opgeslagen')

        // After the successModal, so as to not override the questionModal below
        if (!translated && this.nlValuesValid(nlValues)) {
          this.$refs[`${textType}Texts`].askForTranslation()
        }
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het opslaan van de teksten')
      }
    },
    configureTranslatables (translatables) {
      // Format the translatables in standard way so we can use them throughout the rest of the component
      const title = translatables.filter(desc => desc.type === 0)[0]
      const short = translatables.filter(desc => desc.type === 1)[0]
      const long = translatables.filter(desc => desc.type === 2)[0]
      const social = translatables.filter(desc => desc.type === 3)[0]

      const config = []
      config[0] = {
        id: title?.id,
        label: 'Titel',
        field: 'title',
        text_nl: title?.text?.text_nl,
        text_fr: title?.text?.text_fr,
        text_en: title?.text?.text_en
      }
      config[1] = {
        id: short?.id,
        label: 'Korte beschrijving',
        field: 'short_description',
        text_nl: short?.text?.text_nl,
        text_fr: short?.text?.text_fr,
        text_en: short?.text?.text_en
      }
      config[2] = {
        id: long?.id,
        label: 'Beschrijving',
        field: 'description',
        text_nl: long?.text?.text_nl,
        text_fr: long?.text?.text_fr,
        text_en: long?.text?.text_en
      }
      config[3] = {
        id: social?.id,
        label: 'Social media title',
        field: 'social_title',
        text_nl: social?.text?.text_nl,
        text_fr: social?.text?.text_fr,
        text_en: social?.text?.text_en
      }
      return config
    },
    async createStrengths () {
      try {
        const response = await createPropertyStrengths(this.propertyId)
        await this.loadPropertyStrengths()
        this.showStrengthsForm = !this.showStrengthsForm
        return response
      } catch {
        errorModal('Er ging iets mis bij het aanmaken van de troeven')
      }
    },
    async submit (data) {
      try {
        const response = await updatePropertyMarketing(this.propertyId, data)
        await this.loadPropertyStrengths()
        this.showStrengthsForm = !this.showStrengthsForm
        return response
      } catch {
        errorModal('Er ging iets mis bij het opslaan van de troeven')
      }
    },
    cancel () {
      this.showStrengthsForm = !this.showStrengthsForm
    },

    async createInvestmentTexts () {
      try {
        const medium = 2 // investment medium
        const response = await createPropertyDescriptions(this.propertyId, { medium })
        await this.loadEntityDescriptions()
        this.editInvestment = true
        return response
      } catch {
        errorModal('Er ging iets mis bij het aanmaken van de investeringsteksten')
      }
    },
    getDescriptionSuffix (descriptions) {
      if (this.propertyId) {
        const description = descriptions.find(({ type }) => type === 2)
        return description?.description_suffix
      }
      return ''
    },
    getReservedTextLength (lang) {
      if (this.propertyId) {
        const descriptionSuffix = this.entityDescriptionsWeb[0]?.description_suffix[lang]
        return descriptionSuffix.length
      }
      return 0
    }
  }
}
</script>
